import { Controller } from "@hotwired/stimulus"

import Flatpickr from 'stimulus-flatpickr'

export default class extends Flatpickr {
// export default class extends Controller {

  static values = { defaultDate: String }

  initialize() {
    console.log(this.defaultDateValue);
    this.config = {
      mode: "range",
      default_date: this.defaultDateValue
    };
  }

  close(){
    let dropdownTarget = document.getElementById("dropdown-btn-element")
    dropdownTarget.classList.remove("hidden")

    let datePickerTarget = document.getElementById("date-picker")
    datePickerTarget.parentElement.classList.add("hidden")

    console.log("closedddd");
  }
}
