import { Controller } from "@hotwired/stimulus"
import { FetchRequest, patch } from "@rails/request.js"

export default class extends Controller {
  static targets = [ "content", "contentHeader", "contentBody", "loading" ]

  async connect() {
    await this.init()
    this.show_errors()

    if (this.current_url.searchParams.get("pp") === "true") {
      this.loadingTarget.classList.remove("hidden")
      this.contentHeaderTarget.classList.add("hidden")
      this.contentBodyTarget.innerHTML = "Verification was submitted and will start processing momentarily"
      this.check()
    }
  }

  async init() {
    let stripeMeta  = document.querySelector('meta[name="stripe-key"]')
    this.current_url = new URL(window.location.href)

    if (stripeMeta === null) { return }
    
    let stripeKey = stripeMeta.getAttribute("content")
    this.stripe   = Stripe(stripeKey)
    this.verification_session = await this.get_verification_session()
  }

  show_errors() {
    if (this.verification_session.last_error != null) {
      this.contentBodyTarget.innerHTML = this.verification_session.last_error.reason
      this.contentBodyTarget.classList.remove("text-gray-900")
      this.contentBodyTarget.classList.add("text-red-600")
    }
  }

  async get_verification_session() {
    const request = new FetchRequest("get", "/account_verification/session", {
      responseKind: "json"
    })
    const response = await request.perform()
    if (response.ok) {
      return JSON.parse(await response.text)
    }
  }

  async submit(event) {
    event.preventDefault()
    this.loadingTarget.classList.remove("hidden")
    this.contentHeaderTarget.classList.add("hidden")
    this.contentBodyTarget.classList.add("hidden")

    const body = await this.get_verification_session()

    if (body.location) {
      return window.location.href = body.location
    }

    // Show the verification modal.
    const { error } = await this.stripe.verifyIdentity(body.client_secret);

    if (error) {
      if (error.type == "user_action" && error.code == "session_cancelled") {
        console.log("user canceled");
        this.loadingTarget.classList.add("hidden")
        this.contentHeaderTarget.classList.remove("hidden")
        this.contentBodyTarget.classList.remove("hidden")
        this.contentBodyTarget.innerHTML = "You canceled the verification, to verify your account click here"
      }
      console.error('Error:', error);
    } else {
      return window.location.href = `${window.location.pathname}?pp=true`;
    }
  }

  async check(event) {
    // this handles the error when this function is called when this controller is connected - connect()
    if (typeof event != "undefined") {
      event.preventDefault()
    }
    
    const request = new FetchRequest("get", "/account_verification/status", {
      responseKind: "json"
    })
    const response = await request.perform()
    let status = JSON.parse(await response.text);

    console.log(status);
    window.location.href = `/after_signup/kyc`
  }
}