import { Controller } from "@hotwired/stimulus"
import Tracker from '@openreplay/tracker';

export default class extends Controller {
  static values = {
    apiKey: String,
    userId: String, // this is email for primary identifier for open replay
    metaUserId: String,
    metaUserName: String,
    metaAccountId: String,
    metaAccountName: String,
    metaIpAddress: String,
    metaHost: String,
  }

  connect() {
    const tracker = window.__OPENREPLAY__;
    if (tracker) {
      this.addMetaProperties(tracker);
    } else {
      const tracker = this.initializeOpenReplay();
      this.startOpenReplay(tracker);
    }
  }

  addMetaProperties(tracker) {
    tracker.setUserID(this.userIdValue);
    tracker.setMetadata({
      metaUserId: this.metaUserIdValue,
      metaUserName: this.metaUserNameValue,
      metaAccountId: this.metaAccountIdValue,
      metaAccountName: this.metaAccountNameValue,
      metaIpAddress: this.metaIpAddressValue,
      metaHost: this.metaHostValue,
    });
  }

  initializeOpenReplay() {
    const tracker = new Tracker({
      projectKey: this.apiKeyValue,
      obscureTextEmails: false,
      obscureInputEmails: false,
      obscureTextNumbers: false,
      defaultInputMode: 0,  
    });
    return tracker;
  }

  startOpenReplay(tracker) {
    tracker.start({
      userID: this.userIdValue,
      metadata: {
        metaUserId: this.metaUserIdValue,
        metaUserName: this.metaUserNameValue,
        metaAccountId: this.metaAccountIdValue,
        metaAccountName: this.metaAccountNameValue,
        metaIpAddress: this.metaIpAddressValue,
        metaHost: this.metaHostValue,
      }
    });

    return tracker;
  }
}

