import { Controller } from "@hotwired/stimulus"
import { Turbo } from "@hotwired/turbo-rails"
import { FetchRequest } from "@rails/request.js"

export default class extends Controller {
  async switch(event) {
    let account_id = event.target.selectedOptions[0].value;
    const request = new FetchRequest('patch', `/accounts/${account_id}/switch/`, { body: JSON.stringify({redir: "/"}), responseKind: "json"  })
    const response = await request.perform()
    if (response.ok) {
      const body = await response.text
      Turbo.visit(window.location)
    }
  }
}