import { Controller } from "@hotwired/stimulus"
import { FetchRequest } from "@rails/request.js"

export default class extends Controller {
  static targets = [ "description", "refresh", "retry" ]

  connect() {
    this.process_section()
    setTimeout(() => {
      this.refreshTarget.classList.remove('hidden')
    }, 2500)
  }

  async process_section() {
    this.refreshTarget.innerHTML = "Refreshing..."
    this.refreshTarget.disabled = true;

    let params = new URLSearchParams(window.location.search)

    const request = new FetchRequest("post", `/ss/${params.get("ss")}/process_section`, {
      body: {
        typeform_form_id: params.get("fid"),
        typeform_response_id: params.get("res_id"),
        uuid: params.get("uuid")
      },
      responseKind: "json"
    })

    const response = await request.perform()

    this.refreshTarget.innerHTML = "Refresh"
    this.refreshTarget.disabled = false;

    if (response.ok) {
      // Turbo.clearCache()
      // Turbo.visit(`/ss/${params.get("ss")}`)
      // console.log(JSON.parse(await response.text));
    } else {
      this.descriptionTarget.innerHTML = "There was an issue with submitting the quiz to the server. Unfortunately we can't process your quiz now, if you retry the quiz things might clear up but you could also reach out to us and we will get it fixed asap"
      this.retryTarget.classList.remove('hidden')
      console.log(await response.text);
      let error = JSON.parse(await response.text)
      // if (error.uuid == "has already been taken") {
      //   Turbo.clearCache()
      //   Turbo.visit(`/ss/${params.get("ss")}`)
      // }
    }
  }
}