import { Controller } from "@hotwired/stimulus"
import { Turbo } from "@hotwired/turbo-rails"
import consumer from "../channels/consumer"

export default class extends Controller {
  connect(event) {
    // console.log("qqqq");
  }

  reconnect(event) {
    location.reload(true);
  }
}