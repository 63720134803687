import { Application } from "@hotwired/stimulus"
import {install, uninstall} from '@github/hotkey'

const application = Application.start()

// Configure Stimulus development experience
application.debug    = false
window.Stimulus      = application

export { application }

document.addEventListener("turbo:load", function() {
  // Install all the hotkeys on the page
  for (const el of document.querySelectorAll('[data-hotkey]')) {
    uninstall(el)
    install(el)
  }
})

