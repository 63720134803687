import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "timer", "output" ]

  connect() {
    this.count_down()
  }

  count_down() {
    var timeleft = 4;
    let timer_element = this.timerTarget;

    var timer = setInterval(function(){
      if(timeleft <= 0){
        clearInterval(timer);
        window.location.href = "/"
      } else {
        timer_element.innerHTML = timeleft;
      }
      timeleft -= 1;
    }, 1000);
  }
}