import { Controller } from "@hotwired/stimulus"
import { Turbo } from "@hotwired/turbo-rails"
import consumer from "../channels/consumer"

export default class extends Controller {
  static targets = [ "yield", "reconnect" ]

  static values = {
    name: String,
  }

  connect(event) {
    this.subscription = consumer.subscriptions.create({ channel: this.nameValue, id: this.data.get("id") }, {
      connected: this._connected.bind(this),
      disconnected: this._disconnected.bind(this),
      received: this._received.bind(this)
    })
  }

  channel() {
    console.log("sssss");
  }

  _connected() {
    console.log("section_channel_connected");
    // TODO should we have this here?
    // this.yieldTarget.classList.remove("hidden");
  }

  _disconnected(event) {
    console.log("section_channel_disconnected");
    this.yieldTarget.remove();
    this.reconnectTarget.classList.remove("hidden");
  }

  _received(data) {
    console.log("sssss");
    console.log(data);
    // Called when there's incoming data on the websocket for this channel
    if(data.type == 'redirect') {
      window.location.href = data.url
    } else {
      console.log("received data");
      console.log(data);
    }
  }
}
