import { Controller } from "@hotwired/stimulus"
import { Turbo } from "@hotwired/turbo-rails"

export default class extends Controller {
  static values = {
  }
  static targets = [""]

  connect() {
    
  }

  switch(event) {
    // https://stackoverflow.com/a/8549358
    var locale = event.target.options[event.target.selectedIndex].value;
    console.log(locale);
  }
}