import { Controller } from "@hotwired/stimulus"
import { Turbo } from "@hotwired/turbo-rails"
import { FetchRequest } from "@rails/request.js"

export default class extends Controller {
  async redirect(event) {
    let redirect_path = event.target.selectedOptions[0].value;
    Turbo.visit(redirect_path)
  }
}
