import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets =  [ "timeLeft" ]
  
  initialize() {
  }
  
  connect() {
    // in seconds
    var totalTime = this.timeLeftTarget.dataset.info
    var startTime = new Date().getTime();

    this.interval = setInterval(function(){
      // in seconds
      var elapsedTime = Math.round((new Date().getTime() - startTime)/1000)
  
      var hoursLeft = Math.floor((totalTime - elapsedTime)/3600)
      var minutesLeft = Math.floor(((totalTime - elapsedTime)/60) % 60)
      var secondsLeft = Math.floor((totalTime - elapsedTime) % 60)
  
      if (secondsLeft > -1) {
        var minutes = minutesLeft
        var seconds = secondsLeft
  
        if (minutesLeft < 10) {
          minutes = "0" + minutesLeft
        } 
  
        if (secondsLeft < 10) {
          seconds = "0" + secondsLeft
        } 
  
        var hours = "0" + hoursLeft
        
        var text = hours + ":" + minutes + ":" + seconds
      } else {
        var text = "Your time is up!"
        clearInterval(this.interval)
      }
  
      document.getElementById("time_left").innerHTML = text 
    }, 1000);  
    
    

    //  window.onload = setInterval(getTimeLeft(), 1000);  
    // debugger
  }

  disconnect() {
    clearInterval(this.interval)
  }
}