import { Controller } from "@hotwired/stimulus"

// export default class extends Flatpickr {
export default class extends Controller {
  // static targets = ["dropdown"]

  initialize() {
    // console.log(this.element);
  }

  custom() {
    let dropdownTarget = document.getElementById("dropdown-btn-element")
    dropdownTarget.classList.add("hidden")

    let datePickerTarget = document.getElementById("date-picker")
    datePickerTarget.parentElement.classList.remove("hidden")
    datePickerTarget.click()
  }
}
