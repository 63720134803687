import { Controller } from "@hotwired/stimulus"
import { FetchRequest } from "@rails/request.js"

export default class extends Controller {
  static values = { id: String, formId: String, sectionId: Number }

  async sync() {
    const request = new FetchRequest("post", `/ss/${this.sectionIdValue}/process_section`, {
      body: {
        typeform_form_id: this.formIdValue,
        typeform_response_id: this.idValue
      },
      responseKind: "json"
    })

    const response = await request.perform()

    this.refreshTarget.innerHTML = "Refresh"
    this.refreshTarget.disabled = false;

    if (response.ok) {
      console.log("success");
    } else {
      console.log(await response.text);
      let error = JSON.parse(await response.text)
    }
  }
}
