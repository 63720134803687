import { Controller } from "@hotwired/stimulus"
import { autofill } from '@mapbox/search-js-web';

// Connects to data-controller="map-search"
export default class extends Controller {
  static targets = ['manualEntry', 'secondaryInputs', 'line1', 'district', 'country'];
  static values = {
    accessToken: String
  }

  connect() {
    console.log("MapSearch controller connected");
    let autofillCollection = autofill({
      accessToken: this.accessTokenValue,
      options: { country: 'us' }
    })

    autofillCollection.addEventListener(
      "retrieve",
      async (e) => {
        this.setLine1(e.detail.features[0].properties.address_line1);
        this.setDistrict(e.detail.features[0].properties.district);
        this.setCountry(e.detail.features[0].properties.country_code);
      }
    );
  }

  setLine1(line1) {
    if (this.line1Target) {
      this.line1Target.value = line1;
    }
  }

  setDistrict(district) {
    if (this.districtTarget) {
      this.districtTarget.value = district;
    }
  }

  setCountry(country) {
    if (this.countryTarget) {
      this.countryTarget.value = country;
    }
  }
}
