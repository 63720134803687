import { Controller } from "@hotwired/stimulus"
import { Turbo } from "@hotwired/turbo-rails"

export default class extends Controller {
  connect() {
  }

  go_to_link() {
    let select = this.element
    let selected_option = select.options[select.selectedIndex]
    Turbo.visit(selected_option.value);
  }
}