import { Controller } from "@hotwired/stimulus"
// import { debounce } from "debounce";

export default class extends Controller {
  connect(event) {
    let input = document.getElementById("search")
    input.addEventListener("keydown", (event) => {
      if (event.isComposing || event.keyCode === 27) {
        document.getElementById("search").blur();
      }
    })
  }

  focus(event) {
    event.preventDefault()
    document.getElementById("search").focus()
  }

  update(event) {
    console.log("searching");
    // this.element.requestSubmit();
  }
}
