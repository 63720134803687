// Load all the controllers within this directory and all subdirectories.
// Controller files must be named *_controller.js.

import { application } from "./application"

application.handleError = handleError

// Register each controller with Stimulus
import controllers from "./**/*_controller.js"
controllers.forEach((controller) => {
  application.register(controller.name, controller.module.default)
})

import manager_controllers from "./**/manager/*_controller.js"
manager_controllers.forEach((controller) => {
  application.register(controller.name, controller.module.default)
})

import { Alert, Dropdown, Modal, Tabs, Popover, Toggle, Slideover } from "tailwindcss-stimulus-components"
application.register('alert', Alert)
application.register('dropdown', Dropdown)
application.register('modal', Modal)
application.register('tabs', Tabs)
application.register('popover', Popover)
application.register('toggle', Toggle)
application.register('slideover', Slideover)

import Flatpickr from 'stimulus-flatpickr'
application.register('flatpickr', Flatpickr)

import Hotkeys from 'stimulus-hotkeys'
application.register('hotkeys', Hotkeys)

import Chartkick from "chartkick"
import "chartkick/chart.js"
application.register('chartkick', Chartkick)

function handleError(error, message, detail) {
  const tags = { identifier: detail.identifier }
  const extra = { message }
  Sentry.captureException(error, { tags, extra })

  const errorInfo = `Error ID: ${Sentry.lastEventId()}`
  console.error("%s\n\n%s\n\n%o\n\n%o", message, errorInfo, error, detail)
}
